import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoginData, Role } from '../models/authentication.models';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router } from '@angular/router';
import { EnvironmentApiService } from '@uc/utilities';
import { CustomCookieService } from './custom-cookie.service';
import { cookies } from '../static/authentication.properties';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	constructor(
		@Inject(PLATFORM_ID) private _platform: string,
		private _http: HttpClient,
		private _cookie: CookieService,
		private _location: Location,
		private _router: Router,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	async login(args: { email: string; password: string }) {
		const params = {
			email: args?.email,
			password: args?.password,
		};

		return lastValueFrom(
			this._http.post<LoginData>(`${this._apiSrv.API_URL}login`, params),
		);
	}

	signOut(): void {
		/**
		 * if client was logged in, remove client details from local storage
		 */
		const role = this._customCookieSrv.get('role');
		if (isPlatformBrowser(this._platform) && role === Role.CLIENT) {
			const localStorageProperties = [
				'ngStorage-uc_clientProductUg',
				'ngStorage-uc_clientId',
				'ngStorage-uc_clientProductCle',
				'ngStorage-uc_clientLogo',
				'ngStorage-uc_clientName',
				'ngStorage-uc_isClientAuthenticated',
				'ngStorage-uc_clientProductPg',
			];

			localStorageProperties.forEach((prop) => {
				localStorage.removeItem(prop);
			});
		}

		this._deleteUcCookies();
		this._customCookieSrv.reset();

		const path = this._location.path();

		if (path.includes('user-profile')) {
			this._router.navigate(['/']);
			setTimeout(() => {
				if (isPlatformBrowser(this._platform)) {
					location.reload();
				}
			}, 200);
		} else {
			if (isPlatformBrowser(this._platform)) {
				location.reload();
			}
		}
	}

	private _deleteUcCookies() {
		for (const key in cookies) {
			this._cookie.delete(`uc-${key}`, '/');
		}
		this._cookie.delete('uc-email', '/');
	}
}
